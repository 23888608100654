<template>
	<Alert :message="error" v-if="error" />
	<!-- Documents -->
	<div class="row">
		<div class="upload-container">
			<!-- Add new document button -->
			<div
				class="btn btn-icon"
				v-if="access && !upload && !archived && !uploading"
				@click="showUploadDoc"
			>
				<div class="text">Add Document</div>
				<fa icon="file" />
			</div>
		</div>

		<!-- Add new doc form -->
		<div class="upload-container">
			<div class="file-container uploader" v-if="upload">
				<div v-if="uploading">
					<page-loading />
				</div>

				<div class="flex" v-else>
					<input
						type="file"
						class="custom-file-input"
						@change="onFileChange"
					/>

					<div class="btn-icon muted" @click="showUploadDoc">
						<fa icon="times" />
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="documents">
		<div class="document" v-if="filesEmpty">
			<div class="content">
				<fa class="doc-icon" icon="folder-open" />
				<div class="title">No docs...</div>
			</div>
		</div>

		<div v-for="file in documents" :key="file.id">
			<div class="document">
				<fa
					class="btn-deleteDoc"
					icon="times-circle"
					v-if="access"
					@click="handleDelete(file.id)"
					
				/>
				<a
					:href="`${file.downloadUrl}`"
					target="new"
					:title="`${file.fileName}`"
				>
					<div
						class="content"
						v-if="!deleting || deleting != file.id"
					>
						<!-- <fa class="doc-icon" icon="file-pdf" /> -->
						<fa class="doc-icon" :icon="getIcon(file.fileName)" />

						<div class="title">{{ file.fileName }}</div>
					</div>
					<div class="content" v-else>
						<fa class="doc-icon" icon="trash" />
						<div class="title">deleting...</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Alert from "./Alert.vue";
import useStorage from "../composables/useStorage";
import useCollection from "../composables/useCollection";
import { projectFirestore } from "../firebase/config";
import getCollection from "../composables/getCollection";
import { computed, watchEffect } from "@vue/runtime-core";
import PageLoading from "./PageLoading.vue";
import { v4 as uuidv4 } from "uuid";
import account from "../composables/account";
import equipmentCollection from "../composables/equipmentsCollection";
import { async } from "q";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFilePowerpoint,
	faFileAlt,
	faFileCsv,
} from "@fortawesome/free-solid-svg-icons";

export default {
	props: [
		"companyId",
		"collection",
		"assetId",
		"access",
		"archived",
		"equipmentId",
		"fileType",
		"showAddIcon",
	],
	components: { Alert, PageLoading, FontAwesomeIcon },
	setup(props) {
		const companyId = props.companyId;
		const collection = props.collection;
		const assetId = props.assetId;
		const access = props.access;
		const archived = props.archived;
		const showAddIcon = props.showAddIcon;
		const error = ref(null);

		let id = uuidv4();
		const { userDoc } = account();
		// Data
		let db = projectFirestore;
		let companyRef = db.collection("companies").doc(companyId);
		let storageRef = companyRef
			.collection(collection)
			.doc(assetId)
			.collection("storage");

		const { documents: storage } = getCollection(storageRef);
		const { addEquipmentDoc } = equipmentCollection();
		// select file
		const types = [
			"text/csv",
			"application/pdf",
			"application/msword",
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			"text/plain",
			"application/vnd.ms-excel",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			"application/vnd.ms-powerpoint",
			"application/vnd.openxmlformats-officedocument.presentationml.presentation",
		];
		const file = ref(null);

		const uploading = ref(false);
		const success = ref(null);
		const deleting = ref(null);

		const upload = ref(false);
		const showUploadDoc = () => {
			if (upload.value) {
				upload.value = false;
			} else {
				upload.value = true;
			}
		};

		const filesEmpty = computed(() => {
			if (storage.value) {
				return storage.value.length == 0;
			}
		});

		const documents = computed(() => {
			if (storage.value) {
				console.log("Storage: ", storage.value);
				return storage.value.filter(
					(file) => !file.isArchived && file.fileType != "image"
				);
			} else {
				return [];
			}
		});

		// Select file
		const onFileChange = (e) => {
			error.value = null;

			const selected = e.target.files[0];
			console.log("Selected type: ", selected.type);
			if (
				selected &&
				types.includes(selected.type) &&
				selected.size < 5000000
			) {
				file.value = selected;
				error.value = null;
				handleUpload();
			} else {
				file.value = null;
				error.value =
					"Must be pdf,doc,docx,csv, txt,xls,xlsx,ppt or pptx and less than 5mb";
			}
		};

		// Upload file
		const handleUpload = async () => {
			error.value = null;
			uploading.value = true;
			success.value = null;

			if (file.value) {
				const {
					uploadFile,
					downloadUrl,
					error: uploadErr,
				} = useStorage();
				var docType = "documents";

				let filePath = `${companyId}/${collection}/${assetId}/${docType}/${file.value.name}`;

				await uploadFile(filePath, file.value);

				if (uploadErr.value) {
					error.value = uploadErr.value;
					uploading.value = false;
					return;
				}

				const { addDoc, error: addDocErr } = useCollection(storageRef);
				console.log(props, "hii");
				if (downloadUrl.value) {
					if (props.equipmentId != undefined) {
						id = uuidv4();
						let fileDoc = {
							fileName: file.value.name,
							filePath: filePath,
							downloadUrl: downloadUrl.value,
							fileType: props.fileType,
							isArchived: false,
							ownerId: userDoc.value.id,
							id: id,
						};
						console.log(id, "iddd");
						await addEquipmentDoc(props.equipmentId, id, fileDoc);
					} else {
						let fileDoc = {
							fileName: file.value.name,
							filePath: filePath,
							downloadUrl: downloadUrl.value,
						};

						await addDoc(fileDoc);
					}
				}

				if (addDocErr.value) {
					error.value = "Failed to upload file";
				}

				if (!error.value) {
					success.value = "File uploaded";
					uploading.value = false;
					setTimeout(() => {
						success.value = null;
					}, 1000);
				}
			} else {
				uploading.value = false;
				error.value = "No file selected";
			}
		};

		// Delete document
		const handleDelete = async (docId) => {
			const { updateDoc } = useCollection(storageRef);
			await updateDoc(docId, { isArchived: true });
		};

		// Get icon based on fileType
		const getIcon = (fileName) => {
			const fileExtension = fileName
				.substr(fileName.lastIndexOf(".") + 1)
				.toLowerCase();

			const iconMap = {
				csv: faFileCsv,
				pdf: faFilePdf,
				doc: faFileWord,
				docx: faFileWord,
				xls: faFileExcel,
				xlsx: faFileExcel,
				ppt: faFilePowerpoint,
				pptx: faFilePowerpoint,
				txt: faFileAlt,
			};

			return iconMap[fileExtension] || null;
		};

		return {
			upload,
			showUploadDoc,
			file,
			uploading,
			success,
			deleting,
			onFileChange,
			handleUpload,
			handleDelete,
			storage,
			access,
			error,
			filesEmpty,
			archived,
			documents,
			getIcon,
			showAddIcon,
		};
	},
};
</script>

<style lang="scss" scoped>
.documents {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: min-content;
    column-gap: 0.7em;
    row-gap: 0.7em;
    margin-bottom: 1em;
	@include respond(large){
		grid-template-columns: repeat(5, 100px);
	 }
}

.document {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid $rm-light-grey;
    width: 100px;

    height: 100px;
    .btn-deleteDoc {
        display: none;
    }
    &:hover {
        box-shadow: $box-shadow;
        .btn-deleteDoc {
            display: block;
            position: absolute;
            top: -8px;
            right: -8px;
            color: $rm-grey;
            font-size: 1.2em;
            background-color: white;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                color: $rm-danger;
            }
        }
    }
    .content {
        position: relative;
        text-align: center;
        .doc-icon {
            font-size: 1.8em;
            margin-bottom: 0.6em;
        }
        .title {
            font-size: 0.8em;
            width: 70px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.uploader {
	display: flex;
	justify-content: center;
	min-width: 10ch;
	input {
		@include respond(large) {
			width: 30ch;
		}
	}

}
.upload-container {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}
</style>
